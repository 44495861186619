<template>
  <c-grid
      w="100%"
      :h="{ base: 'calc(130% - 20px)', lg: 'calc(100% - 70px)' }"
      :template-columns="{
      base: '1fr',
      lg: '100% 1fr',
      md: '1fr',
      sm: '1fr',
      xs: '1fr',
    }"
      gap="8"
  >
    <c-stack pb="3" px="2" :spacing="8" maxHeight="100%">
      <c-text v-if="!isFetchingSavedPost && !posts.length" fontStyle="italic">
        You do not have any saved post yet!
      </c-text>
      <c-text fontWeight="bold" fontSize="lg">Saved Posts</c-text>
      <template v-for="(post, index) in posts">
        <c-box :key="post.id">
          <PostCard
              :is-saved-post="true"
              :is-edited-post="(passStatus.id === post.id) && passStatus.isEdited"
              @post-deleted="removeDeletedPost(index)"
              @togglePostSave="onPostSaveToggle"
              @selectPostAttachment="
              selectedPost = $event;
              isImagePreviewOpen = true;
            "
              @removeSavedPost="onRemoveSavedPost"
              @deletePost="onDeletePost(post)"
              :post="post.post"
          />
        </c-box>
      </template>
      <infinite-loading @infinite="getMorePosts">
        <span slot="no-more">
          <div style="border: 1px solid rgba(255, 69, 0, 0.45)">
            No more posts
          </div>
        </span>
        <span slot="no-results">
          <div style="border: 1px solid rgba(255, 69, 0, 0.45)">
            No posts found
          </div>
        </span>
      </infinite-loading>
    </c-stack>
  </c-grid>
</template>

<script>
import {addPostSave, deletePostSave, deletePostsSaved, savedPosts} from "@/services/insight";
import PostCard from "@/views/App/Explore/components/PostCard.vue";
import {mapState} from "vuex";
import cloneDeep from "lodash.clonedeep";
import InfiniteLoading from 'vue-infinite-loading'
import {generateID} from "@/helpers/data";

export default {
  name: "SavedPosts",
  components: {
    PostCard,
    InfiniteLoading
  },
  data(){
    return{
      isFetchingSavedPost: false,
      posts: [],
      postsOffset: 0,
      postsLimit: 5,
      totalPosts: 0,
      savedPosts: null,
      selectedPost: {
        attachments: [],
      },
      openPostModal: false,
      isFetchingPosts: false,
      isFetchingMorePosts: false,
      isDeleteDialogOpen: false,
      isImagePreviewOpen: false,
    }
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
  },
  mounted() {
    this.getSavedPosts();
  },
  methods: {
    passStatus(sentPost){
      return sentPost
    },
    onDeletePost(post) {
      this.isDeleteDialogOpen = true;
      this.postToDelete = { ...post };
    },

    getSavedPosts(){
      this.isFetchingSavedPost = true;
      savedPosts({
        userId: this.user.id,
        limit: this.postsLimit,
        offset: this.postsOffset,
      }).then((res) => {
        this.posts = res.data.post_save;
        this.savedPosts = res.data.post_save;
        this.totalPosts = res.data.post_save_aggregate.aggregate.count;
        this.isFetchingPosts = false;
      });

      console.log('Kogaloga==>', this.totalPosts)
    },

    onPostAdded(post) {
      this.posts.unshift({ ...post });
    },

    // handleRemoveSavedPost(removeIndex) {
    //   if( removeIndex > -1 ){
    //     this.posts.splice( removeIndex, 1 )
    //   }
    // },

    getMorePosts($state) {
      console.log('Hinge==>', this.totalPosts)
      this.isFetchingMorePosts = true;
      this.postsOffset = this.postsOffset + this.postsLimit;
      savedPosts({
        userId: this.user.id,
        limit: this.postsLimit,
        offset: this.postsOffset,
      }).then((res) => {
        this.posts = [...cloneDeep(this.posts), ...res.data.post_save];
        this.isFetchingMorePosts = false;
        $state.loaded();
        if (this.totalPosts === this.posts.length) {
          $state.complete();
        }
      });
    },

    removeDeletedPost(deletedIndex){
      if( deletedIndex > -1 ){
        this.posts.splice( deletedIndex,1 )
      }
    },

    async onRemoveSavedPost(saved) {
      deletePostsSaved({userId: this.user.id, postId: saved.id})
          .then(() => {
            this.posts = this.posts.filter(
                (pst) => pst.id !== saved.id
            )
            this.$toast({
              title: 'Success',
              description: `You have successfully removed post from your saved list.`,
              status: 'success',
              position: 'top',
              duration: 3000,
            });
          })
          .catch((e) => {
            console.log(e)
            this.$toast({
              title: 'An error occurred.',
              description: `Couldn't remove saved post from list, please try again.`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          })
    },

    async onPostSaveToggle(post) {
      console.log('posting==>', post)
      const data = {
        postId: post.id,
        userId: this.user.id,
      };
      const postIndex = this.posts.findIndex((_post) => post.id === _post.post.id);
      if (post.post_saves.length) {
        console.log('Not Added');
        const savedPostIndex = this.savedPosts.findIndex(
            (savedPost) => savedPost.post.id === post.id
        );
        const savedPost = cloneDeep(this.savedPosts[savedPostIndex].post);
        console.log('Indingo==>', savedPost)
        this.posts[postIndex].post.post_saves.pop();
        this.savedPosts.splice(savedPostIndex, 1);
        try {
          await deletePostSave(savedPost.id);
        } catch (e) {
          this.savedPosts.splice(savedPostIndex, 0, { ...savedPost });
          this.posts[postIndex].post_saves.push({ ...data });
          this.$toast({
            title: 'An error occurred.',
            description: `Couldn't remove saved post, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      } else {
        console.log('Successfully Added');
        this.posts = this.posts + [post];
        this.posts[postIndex].post.post_saves.push({ ...data });
        const genId = generateID(4);
        this.savedPosts.push({ id: genId, post: { ...post } });
        const savedPostIndex = this.savedPosts.findIndex(
            (savedPost) => savedPost.id === genId
        );
        try {
          const res = await addPostSave(data);
          const { id } = res.data.insert_post_save_one;
          this.savedPosts[savedPostIndex].id = id;
        } catch (e) {
          const savedPostIndex = this.savedPosts.findIndex(
              (savedPost) => savedPost.post.id === post.id
          );
          this.savedPosts.splice(savedPostIndex, 1);
          this.posts[postIndex].post.post_saves.pop();
          this.$toast({
            title: 'An error occurred.',
            description: `Couldn't save post, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },
  }
}
</script>

<style scoped>

</style>